import { datadogRum } from '@datadog/browser-rum';
import { Box, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { Component } from 'react';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      info: null,
    };
  }

  /**
   * @see https://docs.datadoghq.com/error_tracking/frontend/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
   * @param error
   * @param info
   */
  componentDidCatch(error, info) {
    this.setState({ error, info });

    const renderingError = new Error(error.message);
    renderingError.name = 'ReactRenderingError';
    renderingError.stack = info.componentStack;
    renderingError.cause = error;

    datadogRum.addError(renderingError);
  }

  render() {
    if (this.state.error) {
      return (
        <Box padding={2.5} textAlign="center">
          <Box paddingY={2}>
            <Typography variant="h1" component="div">
              <WarningIcon fontSize="inherit" color="error" />
            </Typography>
          </Box>
          <Typography variant="h5" component="h1" gutterBottom>
            Uh-oh! Something went wrong.
          </Typography>
        </Box>
      );
    }

    return this.props.children;
  }
}
