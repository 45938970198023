import { sortBy } from 'lodash';
import useSWR from 'swr';

import StockAPI from '../api/StockAPI';
import * as ContentTypes from '../constants/ContentTypes';
import {
  ImageContentType,
  ImageOrientation,
} from '../constants/SearchQueryParams';
import useContentToken from './useContentToken';

const fetcher = async (url, token) => {
  try {
    const response = await new StockAPI(token).get(url);
    return (
      response
        // Filter to content type-agnostic to avoid duplicate content types from snapshots.
        .filter(({ content_type }) => content_type === 'all')
        .map((category) => ({
          ...category,
          content_type: ContentTypes.getContentType(category.content_type),
        }))
    );
  } catch (error) {
    return [];
  }
};

const supportedContentTypes = [
  { id: ImageContentType.PHOTOS, name: 'Photos' },
  { id: ImageContentType.ILLUSTRATIONS, name: 'Illustrations' },
];

const orientationOptions = [
  { id: ImageOrientation.LANDSCAPE, name: 'Landscape' },
  { id: ImageOrientation.PORTRAIT, name: 'Portrait' },
  { id: ImageOrientation.SQUARE, name: 'Square' },
];

export default function useImageCategories() {
  const { contentToken } = useContentToken();
  const { data = [] } = useSWR(
    contentToken ? ['/images/stock-item/categories', contentToken] : null,
    fetcher
  );

  return {
    contentTypes: supportedContentTypes,
    categories: sortBy(data, 'name'),
    orientationOptions: orientationOptions,
  };
}
