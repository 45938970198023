import 'react-app-polyfill/stable';

import { datadogRum } from '@datadog/browser-rum';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import './assets/fonts/fonts.css';
import './index.css';

import GlobalLoader from './components/GlobalLoader';
import { datadogConfig, datadogEnabled } from './config/datadog';
import UserChannelProvider from './pusher/UserChannelProvider';
import Routes from './routes';
import store from './store';
import { persistor } from './store/persistor';
import theme from './styles/theme';
import './utils/devTools';

if (datadogEnabled) {
  datadogRum.init(datadogConfig);
}

// todo: replace loading={null} with a loading component
render(
  <Provider store={store}>
    <GlobalLoader />
    <PersistGate loading={null} persistor={persistor}>
      <MuiThemeProvider theme={theme}>
        <UserChannelProvider>
          <Routes />
        </UserChannelProvider>
      </MuiThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
