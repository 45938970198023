import { DefaultPrivacyLevel } from '@datadog/browser-rum';

import { IS_BROWSER_SUPPORTED } from '../utils/BrowserUtils';
import toBool from '../utils/toBool';

export const datadogEnabled =
  IS_BROWSER_SUPPORTED && toBool(process.env.REACT_APP_DD_RUM_ENABLED);

/**
 * @type {import('@datadog/browser-rum').RumInitConfiguration}
 */
export const datadogConfig = {
  applicationId: process.env.REACT_APP_DD_RUM_APP_ID,
  clientToken: process.env.REACT_APP_DD_RUM_TOKEN,
  site: 'datadoghq.com',
  service: process.env.REACT_APP_DD_SERVICE,
  env: process.env.REACT_APP_DD_ENV,
  version: process.env.REACT_APP_COMMIT_REF,
  sessionSampleRate: Number(process.env.REACT_APP_DD_RUM_SAMPLE_RATE),
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
};
