import { datadogRum } from '@datadog/browser-rum';

import { sendErrorEvent } from '../events/sendAvroEvent';

export default async function analyticsErrorTransport({ name, extras }) {
  const { error, ...otherExtras } = extras || {};

  try {
    await sendErrorEvent({
      errorContext: otherExtras,
      errorMessage: error?.message,
      errorStackTrace: error?.stack,
      errorType: name,
    });
  } catch (newError) {
    // Cannot log an error here in the usual way because it can produce an infinite loop
    const ddError = new Error(error?.message);
    ddError.name = 'AnalyticsFallbackError';
    ddError.stack = error?.stack;
    ddError.cause = error;

    datadogRum.addError(ddError);
  }
}
